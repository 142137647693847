import Select2 from "select2"
require("select2/dist/js/i18n/ja")
const load_select2 = () => {
  $(".js_searchable").select2()
}
const select_contents_to_add = () => {
  var v = $('#content-list option:selected').val();
  insert_content(v);
  insert_content("\n");
}
const insert_content = (v) => {
  var f = $('.content_textarea');
  var t = f.val();
  var len = t.length;
  var position = f[0].selectionStart;
  var before_position = t.substr(0, position);
  var after_position = t.substr(position, len);
  if(t.length > 0){
    f.val(before_position + v + after_position);
    f[0].focus();
    var focus_position = position+v.length
    f[0].setSelectionRange(focus_position, focus_position);
  }else{
    f.val(v);
  }
}
document.addEventListener('turbolinks:load', () => {
  var card_search_lists = $('.js_searchable_card_link');
  if(typeof(card_search_lists)!=undefined){
    card_search_lists.click(function(){
      var e = $(this).parents('.js_card_search_list')
                     .children('.js_insert_content')
                     .text();
      insert_content(e);
      insert_content("\n");
      return false;
    });
  }
});
window.select_contents_to_add = select_contents_to_add;
window.insert_content = insert_content;
window.load_select2 = load_select2;
