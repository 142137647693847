document.addEventListener('turbolinks:load', () => {
  var btns = document.getElementsByClassName('js-answer-button');

  if(typeof(btns)!=undefined){
    for(var i=0;i<btns.length;i++){
      btns[i].addEventListener('click', (e) => {
        var main = e.target.closest('.js-card-container');
        var el = main.getElementsByClassName('js-continuation');
        var card_uid = main.id.replace('card-','');

        el[0].classList.remove('hidden');
        var button = e.target.closest('.js-answer-button');
        button.classList.add('hidden');
        var answers = document.getElementsByClassName('js-card-answer-body-answers-'+card_uid);
        var res = [];
        var input_v = document.getElementsByClassName('js-answer-input-'+card_uid)[0].value;

        document.getElementsByClassName('js-card-answer-body-'+card_uid)[0].innerHTML = '<div class="callout"><strong>あなたの答え</strong><br>' + input_v + '</div>';
        document.getElementsByClassName('js-answer-input-'+card_uid)[0].classList.add('hidden');

        for(var i=0;i<answers.length;i++){
          res.push(input_v == answers[i].innerText);
        }

        if(res.indexOf(true) >= 0){
          var newDiv = document.createElement("div");
          var newContent = document.createTextNode('正解！');
          newDiv.classList.add('strong');
          newDiv.appendChild(newContent);
          main.insertBefore(newDiv, el[0]);
          main.getElementsByClassName('js-continuation')[0].classList.add('primary');
        }else{
          var newDiv = document.createElement("div");
          var newContent = document.createTextNode('ざんねん...');
          newDiv.classList.add('strong');
          newDiv.appendChild(newContent);
          main.insertBefore(newDiv, el[0]);
          main.getElementsByClassName('js-continuation')[0].classList.add('alert');
        }
      });
    }
  }
})
