document.addEventListener('turbolinks:load', () => {
  var card_result_buttons = $('.js-card-result');
  if(typeof(card_result_buttons)!=undefined){
    card_result_buttons.click(function(){
      var container = $(this).parents('.js-card-result-button-container');
      if($(this).hasClass('js-correct')) {
        container.html('<div class="callout primary cell small-12">【正解】を選択済み</div>');
      }else{
        container.html('<div class="callout alert cell small-12">【不正解】を選択済み</div>');
      }
    });
  }
});
