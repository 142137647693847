import consumer from "./consumer"
$(function() {
  consumer.subscriptions.create({ channel: 'OpenaiChannel', card_uid: $('#card-main-part').data('card_uid')}, {
    connected() {
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel
      const node = document.getElementById('card-body-'+$('#card-main-part').data('card_uid'));
      node.innerHTML += data.message;
    }});
});