document.addEventListener('turbolinks:load', () => {
  var btns = document.getElementsByClassName('js-choice-button');
  var avaiable_choices = [];

  if(typeof(btns)!=undefined){
    for(var i=0;i<btns.length;i++){
      var choice_uid = btns[i].id.replace('choice-', '');
      avaiable_choices.push(choice_uid);

      btns[i].addEventListener('click', (e) => {
        e.preventDefault();
        var main = e.target.closest('.js-card-container');
        var el = main.getElementsByClassName('js-continuation');
        var card_uid = main.id.replace('card-','');
        var choice_uid = e.target.id.replace('choice-', '');

        if(avaiable_choices.includes(choice_uid)){
          el[0].classList.remove('hidden');
          var container = e.target.closest('.js-card-chioce-container');
          var dummy = container.cloneNode(true);
          container.classList.add('hidden');
          main.insertBefore(dummy, el[0]);
                    
          var tar = dummy.closest('.js-card-chioce-container');
          var buttons = tar.getElementsByClassName('js-choice-button');
          var correct = tar.getElementsByClassName('js-choice-correct');
          var wrong = tar.getElementsByClassName('js-choice-wrong');
          for(var i=0;i<buttons.length;i++){
            buttons[i].classList.add('disabled');
            buttons[i].disable = true;
            buttons[i].href = '';
            //remove: data-method="post"
            //remove: data-remote="true"
            var choice_uid_tmp = buttons[i].id.replace('choice-', '');
            var index = avaiable_choices.findIndex((v) => v === choice_uid_tmp);
            avaiable_choices.splice(index, 1);
          }
          for(var i=0;i<correct.length;i++){
            correct[i].classList.remove('secondary');
            correct[i].classList.add('primary');
          }
          for(var i=0;i<correct.length;i++){
            correct[i].classList.remove('secondary');
            correct[i].classList.add('primary');
          }
          for(var i=0;i<wrong.length;i++){
            wrong[i].classList.remove('secondary');
            wrong[i].classList.add('alert');
          }
          if(e.target.classList.contains('js-choice-correct')){
            var result = true;
            var newDiv = document.createElement("div");
            var newContent = document.createTextNode('正解！');
            newDiv.classList.add('strong');
            newDiv.appendChild(newContent);
            main.insertBefore(newDiv, el[0]);
          }else{
            var result = false;
            var newDiv = document.createElement("div");
            var newContent = document.createTextNode('ざんねん...');
            newDiv.classList.add('strong');
            newDiv.appendChild(newContent);
            main.insertBefore(newDiv, el[0]);
          }
        }
      });
    }
  }
})
