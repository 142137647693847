document.addEventListener('turbolinks:load', () => {
  var bindFacebookEvents, initializeFacebookSDK, loadFacebookSDK, restoreFacebookRoot, saveFacebookRoot;

  $(function() {
    loadFacebookSDK();
    if (!window.fb_events_bound) {
      bindFacebookEvents();
    }
    return true;
  });

  bindFacebookEvents = function() {
    $(document).on('page:fetch', saveFacebookRoot).on('page:change', restoreFacebookRoot).on('page:load', function() {
      return FB.XFBML.parse();
    });
    return window.fb_events_bound = true;
  };

  saveFacebookRoot = function() {
    return window.fb_root = $('#fb-root').detach();
  };

  restoreFacebookRoot = function() {
    if ($('#fb-root').length > 0) {
      return $('#fb-root').replaceWith(window.fb_root);
    } else {
      return $('body').append(window.fb_root);
    }
  };

  loadFacebookSDK = function() {
    window.fbAsyncInit = initializeFacebookSDK;
    return $.getScript("//connect.facebook.net/ja_JP/sdk.js#xfbml=1");
  };

  initializeFacebookSDK = function() {
    return FB.init({
      version: 'v2.3',
      appId: '203357703041773',
      // channelUrl: '//YOUR_WEBSITE_ROOT/channel.html',
      status: true,
      cookie: true,
      xfbml: true
    });
  };
});
