$(function(){
  $("a[href='#']").click(function(){
    return false;
  });
});
document.addEventListener('turbolinks:load', () => {
  $(document).foundation();
});
global.$ = global.jQuery = require('jquery')
require('jquery-ui')
require('foundation-sites')
require('blueimp-file-upload')
require('./select_contents')
require('./medium_uploader')
require('./toggle')
require('./share_button_line')
require('./share_button_twitter')
require('./share_button_facebook')
require('./card_results')
require('./search')
require('./card_choice_results')
require('./card_answer_results')
require('./certifications')
require('./channels/index')
