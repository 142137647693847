document.addEventListener('turbolinks:load', () => {
  var toggle_button = document.getElementById('toggle-button');
  if(toggle_button){
    toggle_button.addEventListener('click', (e) => {
      e.preventDefault();
      $('#toggle-container').toggle();
    });
  }
  var btns = document.getElementsByClassName('js-open-continuation');
  if(typeof(btns)!=undefined){
    for(var i=0;i<btns.length;i++){
      btns[i].addEventListener('click', (e) => {
        e.preventDefault();
        var main = e.target.closest('.js-card-container');
        main.getElementsByClassName('js-continuation')[0].classList.remove('hidden');
        main.getElementsByClassName('js-open-continuation')[0].classList.add('hidden');
      });
    }
  }
  var open_sidebar_button = $('#open-side-off-canvas')[0];
  var close_sidebar_button = $('#close-side-off-canvas')[0];
  open_sidebar_button.addEventListener('click', (e) => {
    $('#side-off-canvas-1').removeClass('opacity-0').addClass('opacity-100')
    $('#side-off-canvas-2').removeClass('-translate-x-full').addClass('translate-x-0');
    $('#side-off-canvas-3').removeClass('opacity-0').addClass('opacity-100')
  });
  close_sidebar_button.addEventListener('click', (e) => {
    if($('#side-off-canvas-1').hasClass('opacity-100')){
      $('#side-off-canvas-1').removeClass('opacity-100').addClass('opacity-0')
      $('#side-off-canvas-2').removeClass('translate-x-0').addClass('-translate-x-full');
      $('#side-off-canvas-3').removeClass('opacity-100').addClass('opacity-0')
      setTimeout(() => {
        $('#side-off-canvas-0').removeClass('z-40').addClass('z-0')
      }, 1000);
    }else{
      $('#side-off-canvas-1').removeClass('opacity-0').addClass('opacity-100')
      $('#side-off-canvas-2').removeClass('-translate-x-full').addClass('translate-x-0');
      $('#side-off-canvas-3').removeClass('opacity-0').addClass('opacity-100')
      $('#side-off-canvas-0').removeClass('z-0').addClass('z-40')
    }
  });
})
